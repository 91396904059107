import { Partner } from './partner';
import { Client } from './client';


export class User {
    static Admin = 'admin';
    static Partner = 'partner';
    static Client = 'client';


    name: string = '';
    role: string;
    role_id: number;
    partner: Partner = null;
    client: Client = null;
    first_name?: string;
    last_name?: string;
    password?: string;
    permissions: string[];

    // role_id: Partner or Client identifier depending on role
    static fromData(username: string, role: string, role_id: number, permissions: string[]): User {
        const user = new User();
        user.name = username;
        user.role = role;
        user.role_id = role_id;
        user.permissions = permissions;
        if (role === User.Partner) {
            user.partner = new Partner();
            user.partner.id = role_id;
        }
        if (role === User.Client) {
            user.client = new Client();
            user.client.id = role_id;
        }
        return user;
    }

    isAdmin(): boolean {
        return this.role === User.Admin;
    }

    isPartner(): boolean {
        return this.partner !== null;
    }

    isClient(): boolean {
        return this.client !== null;
    }

}

