import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  static SUCCESS = 'success';
  static ERROR = 'error';
  static WARNING = 'warning';

  constructor(
    private translateService: TranslateService
  ) { }

  private showAlertSource = new Subject<{ type: string, message: string; }>();

  showAlert$ = this.showAlertSource.asObservable();

  setAlert(alertData = { type: '', message: '' }, error?: {}) {
    if (error) {
      alertData = this.formatErrorMessage(error);
    }
    this.showAlertSource.next(alertData);
  }

  formatErrorMessage(error: any): { type: string, message: string; } {
    return {
      type: 'error', message: this.translateService.instant('__' + `${Object.entries(error.error)[ 0 ].join(' ').
        toLowerCase().replace('.', '').replace(/[ ]/gm, '-')}`)
    };
  }


}