import { Client } from "./client";

export class Partner {

    id: number;
    name: string;
    clients: Client[];

}


