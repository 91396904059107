import { environment } from '../../environments/environment';

export class Settings {
    static BASE_URL = environment.BASE_URL;
    static BASE_API: string = Settings.BASE_URL.concat('api/v1/');

    static UPLOAD_FILE_URL: string = Settings.BASE_API + 'upload/';

    static LOGOUT_URL: string = Settings.BASE_API + 'logout/';
    static LOGIN_URL: string = Settings.BASE_API + 'login/';
    static RESET_PASSWORD: string = Settings.BASE_API + 'password/reset/';
    static CONFIRM_PASSWORD: string = Settings.BASE_API + 'password/reset/confirm/';

    static USERS: string = Settings.BASE_API + 'users/';

    static REFRESH_URL: string = Settings.BASE_API + 'jwt-refresh/';
    static VERIFY_URL: string = Settings.BASE_API + 'jwt-verify/';

    static PARTNER_URL: string = Settings.BASE_API + 'partners/';
    static CLIENT_URL: string = Settings.BASE_API + 'clients/';
    static PROVIDER_URL: string = Settings.BASE_API + 'providers/';

    static INSTRUMENTS_BY_CLIENT_URL: string = Settings.BASE_API + 'instruments/?client={id}';
    static ALL_INSTRUMENTS: string = Settings.BASE_API + 'instruments/';
    static INSTRUMENTS_DRIVERS: string = Settings.BASE_API + 'instruments/drivers/';
    static INSTRUMENTS_DRIVER_CONFIG: string = Settings.BASE_API + 'instruments/drivers/{id}/format/';
    static INSTRUMENT_BY_ID: string = Settings.BASE_API + 'instruments/{id}/';
    static AVAILABLE_INSTRUMENTS_URL: string = Settings.BASE_API + 'instruments/available/?client={id}';
    static ADD_INSTRUMENTS_TO_CLIENT_URL: string = Settings.BASE_API + 'clients/{id}/add-instruments/';
    static GET_ALL_INSTRUMENTS_CONFIG: string = Settings.ALL_INSTRUMENTS + '{id}/instruments_configs/';

    static ALL_INSTRUMENT_CONFIG: string = Settings.BASE_API + 'instrumentsconfigs/';
    static ADD_INSTRUMENT_CONFIG: string = Settings.ALL_INSTRUMENT_CONFIG + 'add_to_instrument/';

    static PROJECT_URL: string = Settings.BASE_API + 'projects/';
    static PROJECT_BY_ID: string = Settings.BASE_API + 'projects/{id}/';
    static PROJECT_CANDIDATES_URL: string = Settings.BASE_API + 'projects/{id}/candidates/';
    static PROJECT_ADD_CANDIDATES_URL: string = Settings.BASE_API + 'projects/{id}/candidates/add/';
    static PROJECT_SEND_REMINDER_URL: string = Settings.BASE_API + 'projects/{id}/send_emails/';
    static PROJECT_GENERATE_RESULTS: string = Settings.BASE_API + 'projects/{id}/summary/';
    static PROJECT_GENERATE_SCORES: string = Settings.BASE_API + 'projects/{id}/get_scores/';
    static PROJECT_ANALYTICS_URL: string = Settings.BASE_API + 'projects/{id}/analytics/';
    static PROJECT_SEND_FEEDBACK: string = Settings.BASE_API + 'projects/{id}/send_emails/';
    static PROJECT_DELETE_CANDIDATES: string = Settings.BASE_API + 'projects/{id}/delete_candidates/';
    static PROJECT_CHECK_CANDIDATES_URL: string = Settings.BASE_API + 'projects/{id}/candidates/check_candidates/';

    static RESULTS_URL: string = Settings.BASE_API + 'results/';
    static RESULTS_REPORT_URL: string = Settings.RESULTS_URL + '{id}/report/';
    static PUBLIC_RESULTS_REPORT_URL: string = Settings.RESULTS_URL + 'public/?token={token}';

    static DEFAULT_LANGUAGE = 'en';
    static TRANSLATIONS_URL = 'https://s3-eu-west-1.amazonaws.com/tkt-translations/';

    static CODICE_URL = environment.CODICE_URL;

}
