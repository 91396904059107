// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://disney-management-dev.thekeytalent.com/',
  CODICE_URL: 'https://myreport-zero.thekeytalent.com/',
  environment_language_file: 'dev-disney_'
};

