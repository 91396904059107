import { Project } from "./project";

export class Client {

    static ACTIVE = 'ACT';
    static INACTIVE = 'INA';

    id: number;
    name: string;
    appearance: { primary_color: string, secondary_color: string, text_color: string, logo: string; }
        = { primary_color: '', secondary_color: '', text_color: '', logo: '' };
    status: string = Client.INACTIVE;
    projects: Project[] = [];
    uuid: string;

    constructor() {

    }
}
